import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Footer from '../../../components/trivia/Footer';
import '../slack/styles.scss';
import './styles.scss';
import { Helmet } from 'react-helmet';
import WorkSpace from '../../../components/trivia/WorkSpace';
import { getUtmParams } from '../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

const users = [
    'Quora-logo.svg',
    'sugoilabs-logo.svg',
    'ea1-logo.svg',
    'consenso-logo.svg',
    'skillenza-logo.svg',
    's-logo.svg',
    'Science-logo.svg',
];
class Games extends React.Component {
    state = {
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    render() {
        const { utm } = this.state;
        return (
            <>
                <Helmet>
                    <title>All Games Types | Trivia</title>
                    <meta
                        name='description'
                        content='With Trivia’s Suite of Games, bring fun and excitement to your virtual teams right inside Slack and Microsoft Teams in an instant. Add Trivia today!'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='All Games Types | Trivia' />
                    <meta
                        property='og:description'
                        content='With Trivia’s Suite of Games, bring fun and excitement to your virtual teams right inside Slack and Microsoft Teams in an instant. Add Trivia today!'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/games/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/TriviaGames.jpg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaGames.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='All Games Types | Trivia' />
                    <meta
                        property='twitter:description'
                        content='With Trivia’s Suite of Games, bring fun and excitement to your virtual teams right inside Slack and Microsoft Teams in an instant. Add Trivia today!'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaGames.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/games/' />
                </Helmet>
                <div className='pageWrap'>
                    <div className='gamesWrap'>
                        <Navbar />

                        <div className='slackHeader'>
                            <h1>The new face of team engagements</h1>
                            <p>
                                A suite of fun-filled games. Purpose-built for stronger, happier, and healthier teams.
                            </p>
                            <div className='addBtn'>
                                <button
                                    onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                    id='TRIVIA-SLACK-INSTALL'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        alt={'slack'}
                                    />
                                    Add Trivia to Slack
                                </button>
                                <button
                                    onClick={() => window.open(encodeURI(`${teamsAddUrl}${utm}`), '_blank')}
                                    id='TRIVIA-TEAMS-INSTALL'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        alt={'microsoft teams'}
                                    />
                                    Add Trivia to Teams
                                </button>
                            </div>
                        </div>
                        <div className='clients-logos'>
                            {users.map((user) => (
                                <span>
                                    <img
                                        src={`https://sw-assets.gumlet.io/trivia/customers/${user}?blur=30`}
                                        data-src={`https://sw-assets.gumlet.io/trivia/customers/${user}`}
                                        alt={user}
                                    />
                                </span>
                            ))}
                        </div>

                        <div className='gamesDescWrap'>
                            <h4>Play, Engage, and Celebrate. All in one place.</h4>

                            <div className='gamesDesc'>
                                <div className='gamesDescImg'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/instant-quizzes.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/instant-quizzes.png'}
                                        alt={'instant quiz'}
                                    />
                                </div>
                                <div className='gamesDescTxt gamesDescSpace'>
                                    <h5>Instant quizzes</h5>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/instant-quizzes.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/instant-quizzes.png'}
                                        alt={'instant quiz'}
                                    />
                                    <p>
                                        With 26K+ fun and quick to launch quizzes, introduce a nice 5-minute break to
                                        your busy workday and engage with your teams, better. Choose from a myriad of
                                        categories to get started instantly.
                                    </p>
                                </div>
                            </div>

                            <div className='gamesDesc'>
                                <div className='gamesDescImg gamesDescSpace'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/unpopular.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/unpopular.png'}
                                        alt={'unpopular opinion'}
                                    />
                                </div>
                                <div className='gamesDescTxt'>
                                    <h5>(Un)popular opinions</h5>
                                    <p>
                                        Conduct simple, native polls, and know what your team’s opinion in minutes, not
                                        through meetings.
                                    </p>
                                </div>
                            </div>

                            <div className='gamesDesc'>
                                <div className='gamesDescImg'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quizzes.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quizzes.png'}
                                        alt={'custom quiz'}
                                    />
                                </div>
                                <div className='gamesDescTxt gamesDescSpace'>
                                    <h5>Custom quizzes</h5>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quizzes.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quizzes.png'}
                                        alt={'custom quiz'}
                                    />
                                    <p>
                                        Craft customized questions and answers, launch them with Trivia. Nurture happy
                                        teams - right where your conversations happen.
                                    </p>
                                </div>
                            </div>

                            <div className='gamesDesc'>
                                <div className='gamesDescImg gamesDescSpace'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/gotcha.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/gotcha.png'}
                                        alt={'gotcha'}
                                    />
                                </div>
                                <div className='gamesDescTxt'>
                                    <h5>Gotcha!</h5>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/gotcha.png?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/gotcha.png'}
                                        alt={'gotcha'}
                                    />
                                    <p>
                                        An MCQ quiz with lots of twists, turns, and baits. Score points for every right
                                        answer and every time you trick your teams into choosing your suggested answer.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <WorkSpace />

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}

export default Games;
